/* Base styles for full-height layout */
html,
body,
#root {
    height: 100%;
    margin: 0;
    padding: 0;
}

/* Main container */
.app-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-color: #f3f4f6;
}

/* Navigation styles */
.nav-main {
    position: sticky;
    top: 0;
    z-index: 1000;
    background-color: #2563eb;
    padding: 1rem;
    color: white;
}

.nav-container {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.nav-logo {
    color: white;
    font-size: 1.5rem;
    font-weight: bold;
}

.nav-links {
    display: flex;
    gap: 0.5rem;
}

/* Add styles for the nav links */
.nav-links button {
    color: white;
}

/* You might also want to style the hover state for better visibility */
.nav-links button:hover {
    background-color: rgba(255, 255, 255, 0.1);
}

/* If you're using the Button component from shadcn/ui, you might need to override some styles */
.nav-links .button {
    color: white;
}

/* For the "Sign In" button, which might have a different style */
.nav-links button[variant="outline"] {
    color: white;
    border-color: white;
}

.nav-links .button.button-ghost {
    color: white;
}

/* Main content area */
.main-content {
    flex: 1;
    overflow-y: auto;
    padding: 2rem 0;
}

/* Page container */
.page-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 1rem;
}

.page-title {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 1rem;
}

/* Home page styles */
.home-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 1rem;
}

.home-title {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
}

.home-subtitle {
    font-size: 1.25rem;
    margin-bottom: 2rem;
}

.home-tabs {
    margin-bottom: 2rem;
}

.search-container {
    display: flex;
    gap: 1rem;
}

.search-input {
    flex-grow: 1;
}

.featured-image {
    width: 100%;
    border-radius: 0.5rem;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

/* Property grid styles */
.property-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 2rem;
}

.property-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
}

.property-title {
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
}

.property-address {
    color: #6b7280;
    margin-bottom: 0.5rem;
}

.property-price {
    font-size: 1.25rem;
    font-weight: bold;
    margin-bottom: 1rem;
}

.property-button {
    width: 100%;
}

/* Sell form styles */
.sell-form {
    padding: 1.5rem;
}

.form-title {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 1rem;
}

.form-content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.form-button {
    width: 100%;
}

/* Home loans styles */
.loans-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 2rem;
}

.loan-options {
    list-style-type: disc;
    padding-left: 1.5rem;
    margin-bottom: 1rem;
}

/* Agent grid styles */
.agent-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 2rem;
}

.agent-image {
    width: 128px;
    height: 128px;
    border-radius: 50%;
    margin: 1rem auto;
}

.agent-content {
    text-align: center;
}

.agent-name {
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
}

.agent-specialty {
    color: #6b7280;
    margin-bottom: 0.5rem;
}

.agent-transactions {
    font-size: 0.875rem;
    margin-bottom: 1rem;
}

.agent-button {
    width: 100%;
}

/* Advertise page styles */
.advertise-content {
    padding: 1.5rem;
}

.advertise-subtitle {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 1rem;
}

.advertise-description {
    margin-bottom: 1rem;
}

.advertise-features {
    list-style-type: disc;
    padding-left: 1.5rem;
    margin-bottom: 1.5rem;
}

.advertise-button {
    width: 100%;
}

/* Help page styles */
.help-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 2rem;
}

.faq-list {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.support-form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.support-textarea {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #d1d5db;
    border-radius: 0.25rem;
}

.support-button {
    width: 100%;
}

/* Sign in page styles */
.signin-container {
    max-width: 400px;
    margin: 0 auto;
}

.signin-title {
    text-align: center;
}

.signin-form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.signin-button {
    width: 100%;
}

.forgot-password {
    text-align: center;
    margin-top: 1rem;
}

.signup-prompt {
    text-align: center;
    margin-top: 1rem;
}

/* Footer styles */
.footer {
    background-color: #1f2937;
    color: white;
    padding: 1rem 0;
}

.footer-content {
    max-width: 1200px;
    margin: 0 auto;
    text-align: center;
}

/*  TOS */
.terms-of-service-card {
    width: 100%;
    max-width: 1020px;
    height: 70vh;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    overflow: hidden;
}

.terms-title {
    font-size: 1.5rem;
    font-weight: bold;
    margin: 0;
}

.terms-content {
    flex-grow: 1;
    overflow-y: auto;
    padding: 2rem;
    background-color: #f9f9f9;
}

.terms-footer {
    background-color: #fff;
    border-top: 5px solid #e0e0e0;
}

.terms-actions {
    display: flex;
    justify-content: flex-end;
    gap: 0.5rem;
}

/* Customize scrollbar for webkit browsers */
.terms-content::-webkit-scrollbar {
    width: 6px;
}

.terms-content::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.terms-content::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 3px;
}

.terms-content::-webkit-scrollbar-thumb:hover {
    background: #555;
}

/* Ensure the content takes up all available space */
.terms-content p {
    margin-bottom: 1rem;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .nav-container {
        flex-direction: column;
        align-items: flex-start;
    }

    .nav-links {
        margin-top: 1rem;
        flex-wrap: wrap;
    }

    .property-grid,
    .loans-grid,
    .agent-grid,
    .help-grid {
        grid-template-columns: 1fr;
    }
}